import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image', 'paintButton', 'spinner'];

  connect() {
    this.angleSteps = [1, 29, 23, 22, 17, 13, 9, 5];
    this.currentStepIndex = 0;
    this.imageUrl = this.element.dataset.imaginHeroImageUrl;
    this.paint = this.getPaintFromUrl() || null;
    window.addEventListener('load', this.deferPreloadImages.bind(this));
    if (this.paint) {
      this.updateImage(); // Update image on initial load if paintId is detected
    }
    window.addEventListener('popstate', this.handlePopState.bind(this));

    this.touchstartX = 0;
    this.touchendX = 0;
    this.addHorizontalSwipeListeners();
  }

  addHorizontalSwipeListeners() {
    this.imageTarget.addEventListener('touchstart', (event) => {
      this.touchstartX = event.changedTouches[0].screenX;
    });

    this.imageTarget.addEventListener('touchend', (event) => {
      this.touchendX = event.changedTouches[0].screenX;
      this.handleGesture();
    });
  }

  handleGesture() {
    if (this.touchstartX - this.touchendX > 70) {
      this.next();
    }

    if (this.touchstartX - this.touchendX < -70) {
      this.previous();
    }
  }

  preloadImages() {
    this.angleSteps.forEach((angle) => {
      const img = new Image();
      img.src = this.getImageUrlWithAngle(angle);
    });
  }

  deferPreloadImages() {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => {
        this.preloadImages();
      });
    } else {
      // Fallback for browsers that do not support requestIdleCallback
      setTimeout(() => {
        this.preloadImages();
      }, 0);
    }
  }

  getImageUrlWithAngle(angle) {
    const url = new URL(this.imageUrl);
    url.searchParams.set('angle', angle);
    if (this.paint) {
      url.searchParams.set('paintId', this.paint.optionId);
      url.searchParams.set('paintDescription', this.paint.description.replace(/\+/g, '%20'));
    }
    return url.toString();
  }

  next() {
    this.currentStepIndex = (this.currentStepIndex + 1) % this.angleSteps.length;
    this.updateImage();
  }

  previous() {
    this.currentStepIndex = (this.currentStepIndex - 1 + this.angleSteps.length) % this.angleSteps.length;
    this.updateImage();
  }

  updateImage() {
    const newAngle = this.angleSteps[this.currentStepIndex];
    this.showSpinner();
    this.imageTarget.src = this.getImageUrlWithAngle(newAngle);
    this.imageTarget.onload = () => {
      this.hideSpinner();
    };
  }

  updateUrl() {
    const url = new URL(window.location);
    url.searchParams.set('paintId', this.paint.optionId);
    url.searchParams.set('paintDescription', this.paint.description.replace(/\+/g, '%20'));
  }

  handlePopState() {
    const newPaint = this.getPaintFromUrl();

    if (this.paint === null || newPaint.optionId !== this.paint.optionId) {
      this.paint = newPaint;
      this.updateImage();
      this.preloadImages();
    }
  }

  getPaintFromUrl() {
    const url = new URL(window.location);
    const paintPreviewId = url.searchParams.get('paint_preview');
    const optionIds = url.searchParams.getAll('option_ids[]');
    const paintsArray = JSON.parse(this.element.dataset.imaginHeroPaintsArray);

    if (paintPreviewId && !optionIds.length) {
      const matchedPaint = paintsArray.find((paint) => paint.optionId === parseInt(paintPreviewId, 10));
      if (matchedPaint) {
        return matchedPaint;
      }
    }

    if (optionIds.length > 0) {
      for (const optionId of optionIds) {
        const matchedPaint = paintsArray.find((paint) => paint.optionId === parseInt(optionId, 10));
        if (matchedPaint) {
          return matchedPaint;
        }
      }
    }
    return null;
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden');
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden');
  }
}
